@font-face {
  font-family: 'pretendard';
  font-weight: 300;
  src: url('./Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'pretendard';
  font-weight: 500;
  src: url('./Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'pretendard';
  font-weight: 700;
  src: url('./Pretendard-ExtraBold.woff') format('woff');
}
